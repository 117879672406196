export const firebaseErrorMessages = {
  "auth/expired-action-code": "The action code or link has expired.",
  "auth/invalid-action-code":
    "The action code is invalid. This can happen if the code is malformed or has already been used.",
  "auth/user-disabled": "The user account has been disabled.",
  "auth/user-not-found": "Account not found! Please sign up instead.",
  "auth/weak-password": "The provided password is too weak. (At least 6 characters)",
  "auth/email-already-in-use":
    "<p>This email is already in use. You can <a href='/forgot-password' style='text-decoration: underline'>reset your password</a> email or <a href='/get-started' style='text-decoration: underline'>create a new account</a>.</p>",
  "auth/invalid-email": "The email address is badly formatted.",
  "auth/wrong-password": "The password is incorrect!",
  "auth/custom-token-mismatch": "The custom token corresponds to a different audience.",
  "auth/invalid-custom-token": "The custom token format is incorrect.",
  "auth/invalid-user-token":
    "The user's credential is no longer valid. The user must sign in again.",
  "auth/user-token-expired": "The user's credential is expired.",
  "auth/credential-already-in-use":
    "This credential is already associated with a different user account.",
  "auth/operation-not-supported-in-this-environment":
    "This operation is not supported in the environment this application is running on.",
  "auth/timeout": "The operation has timed out.",
  "auth/missing-android-pkg-name": "An unexpected error has occurred.",
  "auth/missing-continue-uri": "An unexpected error has occurred.",
  "auth/missing-ios-bundle-id": "An unexpected error has occurred.",
  "auth/invalid-continue-uri": "The continue URL provided in the request is invalid.",
  "auth/unauthorized-continue-uri": "An unexpected error has occurred.",
  "auth/auth-domain-config-required": "An unexpected error has occurred.",
  "auth/cancelled-popup-request":
    "This operation has been cancelled due to another conflicting popup being opened.",
  "auth/popup-blocked": "The popup has been blocked by the browser.",
  "auth/popup-closed-by-user":
    "The popup window was closed by the user without completing the sign-in.",
  "auth/unauthorized-domain": "An unexpected error has occurred.",
};

export const formatFirebaseError = (error) => {
  const code = error?.code;

  let errorMessage = "An unexpected error has occurred!";

  if (code && typeof code === "string") {
    errorMessage = code.substring("auth/".length).replaceAll(/[-]/g, " ");
    errorMessage = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
  }

  return errorMessage;
};

export const getFriendlyErrorMessage = (error) => {
  return firebaseErrorMessages[error.code] || formatFirebaseError(error);
};
