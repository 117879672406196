import { ChevronRightIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  chakra,
  Button,
  FormControl,
  FormLabel,
  Input,
  Text,
  VStack,
  Flex,
  Spinner,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAPI } from "@/contexts/APIContext";
import { useUserData } from "@/contexts/UserDataContext";
import { FadeInTransition } from "@/utils/animation";
import SignInGoogleButton, { isSignInGoogleDisabled } from "./SignInGoogleButton";
import { firebaseErrorMessages, getFriendlyErrorMessage } from "@/utils/firebase";
import Link from "next/link";
import { LinkButton } from "../shared/generic";

const EmailAuth = ({ onComplete, signUp, setErrorMessage }) => {
  const auth = useUserData();

  const [loading, setLoading] = useState(false);
  const { register, handleSubmit } = useForm();

  const onSubmit = async ({ email, password }) => {
    setErrorMessage(null);

    try {
      setLoading(true);
      if (signUp) {
        await auth.signUp(email, password);
        await auth.sendVerification();
      }
      await auth.login(email, password);

      onComplete();
    } catch (error) {
      const message = getFriendlyErrorMessage(error);
      setErrorMessage(message);
    } finally {
      setLoading(false);
      // reset();
    }
  };

  return (
    <chakra.form onSubmit={handleSubmit(onSubmit)} textAlign="center">
      <FormControl id="email" mt={10}>
        <FormLabel>Enter your email address.</FormLabel>
        <Input
          autoComplete="email"
          size="lg"
          type="email"
          placeholder="joe@example.com"
          {...register("email", { required: "Please enter an email." })}
        />
        {/*{!!errors.email && (*/}
        {/*  <FormErrorMessage>{errors.email.message}</FormErrorMessage>*/}
        {/*)}*/}
      </FormControl>

      <FormControl id="password" mt={4}>
        <FormLabel>
          {signUp ? "Pick a password you'll remember." : "Enter your password."}
        </FormLabel>
        <Input
          size="lg"
          autoComplete="current-password"
          type="password"
          placeholder="Password"
          {...register("password", { required: "Please enter a password." })}
        />
        {/*{!!errors.password && (*/}
        {/*  <FormErrorMessage>{errors.password.message}</FormErrorMessage>*/}
        {/*)}*/}
      </FormControl>

      {/*{!!errors.overall && (*/}
      {/*  <Text color="red.700" mt={2}>*/}
      {/*    Error: {errors.overall.message}*/}
      {/*  </Text>*/}
      {/*)}*/}
      {/*{!!errorMessage && (*/}
      {/*  <Text color="red.700" mt={2}>*/}
      {/*    {errorMessage}*/}
      {/*  </Text>*/}
      {/*)}*/}

      <Box textAlign="center" mt={5}>
        <Link href="/forgot-password">Forgot your password?</Link>
      </Box>

      <Button
        type="submit"
        mt={5}
        colorScheme="black"
        size="lg"
        rightIcon={<ChevronRightIcon />}
        isLoading={loading}
        loadingText="Loading..."
      >
        {signUp ? "Sign Up" : "Sign In"}
      </Button>
    </chakra.form>
  );
};

export const MultiMethodAuth = ({ onComplete, signUp }) => {
  const auth = useUserData();
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [hasCompleted, setHasCompleted] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const loginGoogle = async () => {
    setErrorMessage(null);

    try {
      setLoadingGoogle(true);
      await auth.loginGoogle();
      setHasCompleted(true);
      onComplete();
    } catch (e) {
      console.log(e);
      const message = getFriendlyErrorMessage(e);
      setErrorMessage(message);
    } finally {
      setLoadingGoogle(false);
    }
  };

  useEffect(() => {
    setShowEmail(isSignInGoogleDisabled());
  }, []);

  const isErrorUserNotFound = errorMessage === firebaseErrorMessages["auth/user-not-found"];

  return (
    <AnimatePresence mode="wait">
      {hasCompleted ? (
        <FadeInTransition withYMovement key={2}>
          <VStack>
            <Spinner size="xl" />
            <Text mt={4} fontSize="xl">
              Logging in...
            </Text>
          </VStack>
        </FadeInTransition>
      ) : !showEmail ? (
        <FadeInTransition withYMovement key={0}>
          <VStack>
            <SignInGoogleButton onClick={loginGoogle} isLoading={loadingGoogle} />
            {/* create a horizontal line with the word "or" in the middle */}
            <Flex align="center" my={2} w={48}>
              <Box flex="1" height="1px" bg="gray.300"></Box>

              <Box mx={2} color="gray.500" fontSize="sm">
                or
              </Box>

              <Box flex="1" height="1px" bg="gray.300"></Box>
            </Flex>

            <Button
              rounded="full"
              border="1px solid gray"
              size="lg"
              leftIcon={<EmailIcon />}
              onClick={() => setShowEmail(true)}
            >
              Continue with Email
            </Button>
          </VStack>
        </FadeInTransition>
      ) : (
        <FadeInTransition withYMovement key={1}>
          <EmailAuth
            onComplete={onComplete}
            signUp={signUp}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </FadeInTransition>
      )}

      {!!errorMessage && (
        <Text
          color="red.700"
          mt={6}
          display={isErrorUserNotFound && "inline-block"}
          dangerouslySetInnerHTML={{ __html: errorMessage }}
        />
      )}

      {!!errorMessage && isErrorUserNotFound && (
        <LinkButton
          ml={2}
          colorScheme="black"
          size="sm"
          rightIcon={<ChevronRightIcon />}
          href="/get-started"
        >
          Sign Up
        </LinkButton>
      )}

      <Text fontSize={"sm"} textAlign="center" my={4}>
        By creating an account and/or signing in, you agree to our{" "}
        <chakra.a color="blue.500" href="/tos" target="_blank">
          Terms of Service
        </chakra.a>{" "}
        and{" "}
        <chakra.a color="blue.500" href="/privacy-policy" target="_blank">
          Privacy Policy
        </chakra.a>
        .
      </Text>
    </AnimatePresence>
  );
};

export const CollegeCreateAccount = ({ onComplete }) => {
  const auth = useUserData();
  const api = useAPI();

  return (
    <MultiMethodAuth
      signUp
      onComplete={async () => {
        await api.post("users/college-init", true);
        await auth.refreshUserData();
        onComplete();
      }}
    />
  );
};

export const HSCreateAccount = ({ onComplete }) => {
  const auth = useUserData();
  const api = useAPI();

  return (
    <MultiMethodAuth
      signUp
      onComplete={async () => {
        await api.post("users/hs-init", true);
        await auth.refreshUserData();

        onComplete();
      }}
    />
  );
};

export const EnterpriseCreateAccount = ({ onComplete }) => {
  const auth = useUserData();
  const api = useAPI();

  return (
    <MultiMethodAuth
      signUp
      onComplete={async () => {
        await api.post("users/data", true, { type: "enterprise" });
        await auth.refreshUserData();

        onComplete?.();
      }}
    />
  );
};
