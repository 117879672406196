import { AnimatePresence, motion } from "framer-motion";
import { useRouter } from "next/router";
import { createContext, useContext, useState } from "react";

export const FadeInTransition = ({ withYMovement, children }) => (
  <motion.div
    transition={!withYMovement ? { duration: 0.15 } : undefined}
    initial={{ opacity: 0, y: withYMovement ? "1rem" : 0 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: withYMovement ? "1rem" : 0 }}
  >
    {children}
  </motion.div>
);

export const SlideTransition = ({ isReversed, children, ...rest }) => {
  const extent = 8 * (isReversed ? -1 : 1);
  return (
    <motion.div
      transition={{ ease: "easeInOut", duration: 0.15 }}
      initial={{ opacity: 0, x: `${extent}vw` }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: `${-extent}vw` }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

const CrossRouteContext = createContext();

export const useCrossRouteAnimations = () => useContext(CrossRouteContext);

export const CrossRouteAnimation = ({ children }) => {
  const router = useRouter();
  const [pendingURL, setPendingURL] = useState();

  const onFinishNavigate = () => {
    router.push(pendingURL, undefined, { scroll: false });
  };

  const onNavigate = (url) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setPendingURL(url);
  };

  return (
    <CrossRouteContext.Provider value={{ onNavigate }}>
      <AnimatePresence onExitComplete={onFinishNavigate}>{!pendingURL && children}</AnimatePresence>
    </CrossRouteContext.Provider>
  );
};
