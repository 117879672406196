import { Container, chakra, Text, Box } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect } from "react";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import PageInfo from "@/components/PageInfo";
import { MultiMethodAuth } from "@/components/user/CreateAccount";
import { useUserData, withUserData } from "@/contexts/UserDataContext";

const Login = () => {
  const router = useRouter();
  const { currentUser } = useUserData();

  const onComplete = () => {
    setTimeout(() => {
      if (router.query.redirect) {
        router.push(router.query.redirect);
      } else {
        router.push("/");
      }
    }, 200);
  };

  useEffect(() => {
    if (currentUser) {
      onComplete();
    }
  }, [currentUser]);

  return (
    <>
      <PageInfo title="Login" themeColor="white" />

      <Box bg="white">
        <Navbar />

        <Container mt={10} maxW="container.sm" textAlign="center" minH="75vh">
          <chakra.h1 textStyle="pageHeader">Sign in</chakra.h1>

          <Text mt={4} fontSize="2xl" mb={8}>
            Access your AdmitYogi Account Dashboard
          </Text>

          <MultiMethodAuth onComplete={onComplete} />
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default withUserData(Login);
