import { useEffect, useState } from "react";
import { Button, Icon, Image, Text } from "@chakra-ui/react";
import isUaWebview from "is-ua-webview";
import { FaGoogle } from "react-icons/fa";

export const isSignInGoogleDisabled = () => isUaWebview(navigator.userAgent);

export default function SignInGoogleButton({ children, ...rest }) {
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (navigator) {
      setDisabled(isSignInGoogleDisabled());
    }
  }, []);

  return (
    <>
      {disabled && (
        <Text>
          You can&apos;t login with Google using an the in-app browser of apps like Instagram and
          TikTok. Instead, open AdmitYogi.com on your device&apos;s regular browser.
        </Text>
      )}
      <Button
        isDisabled={disabled}
        colorScheme="gray"
        rounded="full"
        border="1px solid gray"
        size="lg"
        {...rest}
      >
        {children ?? (
          <>
            <Image boxSize="1em" src="/images/icons/google.png" alt="Google Logo" mr={2} />
            Continue with Google
          </>
        )}
      </Button>
    </>
  );
}
